import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import React, {
  useCallback,
  useMemo,
  useState
} from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { TelInput } from './TelInput';
import {
  FormLabel,
  Input,
  RequiredIndicator,
} from '@/components';
import {
  nameKanaInput,
} from '@/lib/zod/schema/formInputs';

type TelWithKanaInputProps = {
  defaultTel?: string;
  defaultNameKana?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  isAuthorized?: boolean;
  onReInput(): void;
  onSubmit({
    tel,
    nameKana
  }: { email?: string, tel: string, nameKana: string }): void;
}

const nameKanaFormSchema = z.object({
  nameKana: nameKanaInput.min(1, { message: '入力してください' }),
});
type NameKanaFormSchema = z.infer<typeof nameKanaFormSchema>;

export const TelWithKanaInput = ({
  defaultTel,
  defaultNameKana,
  isDisabled = false,
  isLoading = false,
  isAuthorized = false,
  onReInput,
  onSubmit,
}: TelWithKanaInputProps) => {
  const [tel, setTel] = useState<string>(defaultTel ?? '');
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm<NameKanaFormSchema>({
    resolver: zodResolver(nameKanaFormSchema),
    mode: 'onChange',
    defaultValues: {
      nameKana: defaultNameKana,
    }
  });

  const handleInputted = useMemo(() => {
    return handleSubmit(({ nameKana }) => {
      onSubmit({ nameKana, tel });
    });
  }, [handleSubmit, onSubmit, tel]);

  const [composing, setComposing] = useState(false);
  const handleStartComposition = () => setComposing(true);
  const handleEndComposition = () => setComposing(false);

  const handleEnterKeyDown = useCallback(async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== 'Enter') return false;
    if (composing) return false;

    e.preventDefault();
    await handleInputted();
  }, [composing, handleInputted]);

  return (
    <>
      <TelInput
        defaultTel={defaultTel}
        isDisabled={!!tel}
        isAuthorized={isAuthorized}
        onSubmit={({ tel }) => {
          setTel(tel);
        }}
        onReInput={() => {
          onReInput();
          setTel('');
        }}
      />

      {tel && !isAuthorized && (
        <FormControl
          isInvalid={!!errors.nameKana}
          isRequired={true}
          mt={3}
        >
          <FormLabel
            htmlFor="nameKana"
            requiredIndicator={<RequiredIndicator/>}
          >
            名前カナ
          </FormLabel>
          <Flex
            flexWrap={'nowrap'}
            columnGap={3}
          >
            <Input
              id={'nameKana'}
              required={false}
              isReadOnly={isDisabled}
              placeholder="リラクタロウ"
              bgColor={isDisabled ? 'gray.250' : undefined}
              onCompositionStart={handleStartComposition}
              onCompositionEnd={handleEndComposition}
              onKeyDown={handleEnterKeyDown}
              autoComplete={'off'}
              {...register('nameKana')}
            />
            <Box>
              {isDisabled ? (
                <Button
                  type={'button'}
                  onClick={onReInput}
                  colorScheme={'red'}
                >
                  再入力
                </Button>
              ) : (
                <Button
                  type={'button'}
                  onClick={handleInputted}
                  colorScheme={'brand'}
                  isLoading={isLoading}
                >
                  認証
                </Button>
              )}
            </Box>
          </Flex>
          {!isDisabled && (
            <FormHelperText>
              ※入力のうえ、「認証」ボタンを押してください。
            </FormHelperText>
          )}
          <FormErrorMessage>
            {errors.nameKana?.message}
          </FormErrorMessage>
        </FormControl>
      )}
    </>
  );
};
