import {
  CheckIcon,
} from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  InputGroup,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import React, {
  useCallback,
  useMemo,
  useState
} from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import {
  FormLabel,
  Input,
  RequiredIndicator,
} from '@/components';
import { emailInput } from '@/lib/zod/schema/formInputs';

type EmailInputProps = {
  defaultEmail?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  isAuthorized?: boolean;
  onReInput(): void;
  onSubmit({ email }: { email: string, tel?: string, nameKana?: string }): void;
}

const emailFormSchema = z.object({
  email: emailInput,
});
type EmailFormSchema = z.infer<typeof emailFormSchema>;

export const EmailInput = ({
  defaultEmail,
  isDisabled = false,
  isLoading = false,
  isAuthorized = false,
  onReInput,
  onSubmit,
}: EmailInputProps) => {
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm<EmailFormSchema>({
    resolver: zodResolver(emailFormSchema),
    mode: 'onChange',
    defaultValues: {
      email: defaultEmail,
    }
  });

  const handleInputted = useMemo(() => {
    return handleSubmit(({ email }) => {
      onSubmit({ email });
    });
  }, [handleSubmit, onSubmit]);

  const [composing, setComposition] = useState(false);
  const handleStartComposition = () => setComposition(true);
  const handleEndComposition = () => setComposition(false);

  const handleEnterKeyDown = useCallback(async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== 'Enter') return false;
    if (composing) return false;

    e.preventDefault();
    await handleInputted();
  }, [composing, handleInputted]);

  return (
    <FormControl
      isInvalid={!!errors.email}
      isRequired={true}
    >
      <FormLabel
        htmlFor="email"
        requiredIndicator={<RequiredIndicator/>}
      >
        {isAuthorized && (
          <CheckIcon
            color={'green'}
            mr={2}
          />
        )}
        メールアドレス
      </FormLabel>
      <Flex
        flexWrap={'nowrap'}
        columnGap={3}
      >
        <InputGroup>
          <Input
            id={'email'}
            required={false}
            isReadOnly={isDisabled}
            placeholder="reraku@reraku.jp"
            _placeholder={{ color: 'gray.50' }}
            bgColor={isDisabled ? 'gray.250' : undefined}
            onCompositionStart={handleStartComposition}
            onCompositionEnd={handleEndComposition}
            onKeyDown={handleEnterKeyDown}
            autoComplete={'off'}
            {...register('email')}
          />
        </InputGroup>
        <Box>
          {isDisabled ? (
            <Button
              type={'button'}
              w={'full'}
              onClick={onReInput}
              colorScheme={'red'}
            >
              再入力
            </Button>
          ) : (
            <Button
              type={'button'}
              w={'full'}
              onClick={handleInputted}
              colorScheme={'brand'}
              isLoading={isLoading}
            >
              認証
            </Button>
          )}
        </Box>
      </Flex>
      <FormHelperText>
        ※入力のうえ、「認証」ボタンを押してください。
      </FormHelperText>
      <FormErrorMessage>
        {errors.email?.message}
      </FormErrorMessage>
    </FormControl>
  );
};
