import {
  CheckIcon,
} from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  InputGroup,
  Text,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import React, {
  useCallback,
  useMemo,
  useState
} from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import {
  FormLabel,
  Input,
  RequiredIndicator,
} from '@/components';
import { mobileTelInput } from '@/lib/zod/schema/formInputs';

type TelInputProps = {
  defaultTel?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  isAuthorized?: boolean;
  onReInput(): void;
  onSubmit({ tel }: { email?: string, tel: string, nameKana?: string }): void;
}

const telFormSchema = z.object({
  tel: mobileTelInput,
});
type TelFormSchema = z.infer<typeof telFormSchema>;

export const TelInput = ({
  defaultTel,
  isDisabled = false,
  isLoading = false,
  isAuthorized = false,
  onReInput,
  onSubmit,
}: TelInputProps) => {
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm<TelFormSchema>({
    resolver: zodResolver(telFormSchema),
    mode: 'onChange',
    defaultValues: {
      tel: defaultTel,
    }
  });

  const handleInputted = useMemo(() => {
    return handleSubmit(({ tel }) => {
      onSubmit({ tel });
    });
  }, [handleSubmit, onSubmit]);

  const [composing, setComposition] = useState(false);
  const handleStartComposition = () => setComposition(true);
  const handleEndComposition = () => setComposition(false);

  const handleEnterKeyDown = useCallback(async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode !== 13) return false;
    if (composing) return false;

    e.preventDefault();
    await handleInputted();
  }, [composing, handleInputted]);

  return (
    <FormControl
      isInvalid={!!errors.tel}
      isRequired={true}
    >
      <FormLabel
        htmlFor="tel"
        requiredIndicator={<RequiredIndicator/>}
      >
        {isAuthorized && (
          <CheckIcon
            color={'green'}
            mr={2}
          />
        )}
        電話番号
      </FormLabel>
      <Flex
        flexWrap={'nowrap'}
        columnGap={3}
      >
        <InputGroup>
          <Input
            id={'tel'}
            required={false}
            isReadOnly={isDisabled}
            placeholder="09012341234"
            _placeholder={{ color: 'gray.50' }}
            bgColor={isDisabled ? 'gray.250' : undefined}
            onCompositionStart={handleStartComposition}
            onCompositionEnd={handleEndComposition}
            onKeyDown={handleEnterKeyDown}
            autoComplete={'off'}
            {...register('tel')}
          />
        </InputGroup>
        <Box>
          {isDisabled ? (
            <Button
              type={'button'}
              w={'full'}
              onClick={onReInput}
              colorScheme={'red'}
            >
              再入力
            </Button>
          ) : (
            <Button
              type={'button'}
              w={'full'}
              onClick={handleInputted}
              colorScheme={'brand'}
              isLoading={isLoading}
            >
              認証
            </Button>
          )}
        </Box>
      </Flex>
      {!isDisabled && (
        <FormHelperText>
          <Text>※入力のうえ、「認証」ボタンを押してください。</Text>
          <Text>※SMSが受信可能な電話番号を入力してください。</Text>
        </FormHelperText>
      )}
      <FormErrorMessage>
        {errors.tel?.message}
      </FormErrorMessage>
    </FormControl>
  );
};
