import { ModalContent } from '@chakra-ui/modal';
import {
  Text,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  InputGroup,
  InputRightElement,
  BoxProps,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  Code
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { z } from 'zod';
import { TelInput } from './TelInput';
import {
  AuthenticationCodeInput,
  FormLabel,
  RequiredIndicator,
} from '@/components';
import { EmailInput } from '@/components/Input/WithAuthenticationCodeInput/EmailInput';
import { TelWithKanaInput } from '@/components/Input/WithAuthenticationCodeInput/TelWithKanaInput';
import {
  GraphQLValidationError,
} from '@/graphql/errors';
import {
  AuthenticationKindEnum,
  CustomerVerifyTelOrEmailMutationVariables,
  useCustomerValidateAuthenticationCodeMutation,
  useCustomerVerifyTelOrEmailMutation,
  ValidateAuthenticationCodeMutationPayload
} from '@/graphql/generated/graphql';
import {
  authenticationCodeInput,
} from '@/lib/zod/schema/formInputs';

type WithAuthenticationCodeInputProps = {
  telOrEmail: TelOrEmail;
  authenticationKind: AuthenticationKindEnum;
  defaultTel?: string;
  defaultNameKana?: string;
  defaultEmail?: string;
  errorMessage?: string;
  onAuthorized(customerValidateAuthenticationCode: ValidateAuthenticationCodeMutationPayload, telOrEmailInput: TelOrEmailInput): void;
  onReInput?(): void;
} & BoxProps;

type TelOrEmail = 'TEL' | 'TEL_WITH_KANA' | 'EMAIL';

const authenticationCodeFormSchema = z.object({
  authenticationCode: authenticationCodeInput,
});
type AuthenticationCodeFormSchema = z.infer<typeof authenticationCodeFormSchema>;
export type TelOrEmailInput = { email?: string, tel?: string, nameKana?: string };

const RETRY_SECONDS = 15;

export const WithAuthenticationCodeInput = ({
  telOrEmail,
  authenticationKind,
  defaultTel,
  defaultNameKana,
  defaultEmail,
  errorMessage,
  onAuthorized,
  onReInput,
  ...rest
}: WithAuthenticationCodeInputProps) => {
  const [isInputted, setIsInputted] = useState<boolean>(false);
  const [telOrEmailInput, setTelOrEmailInput] = useState<TelOrEmailInput>({});
  const [authenticationToken, setAuthenticationToken] = useState<string>('');
  const [isAuthorized, setIsAuthorized] = useState<boolean>(false);
  const [reSendTimer, setReSendTimer] = useState<number>(0);
  const sendTimerId = useRef<number>(0);
  const [composing, setComposing] = useState(false);
  const handleStartComposition = () => setComposing(true);
  const handleEndComposition = () => setComposing(false);

  let sendTypeName = 'SMS';
  let inputTypeName = '電話番号';
  if (telOrEmail === 'EMAIL') {
    sendTypeName = 'メール';
    inputTypeName = 'メールアドレス';
  }

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    setFocus,
    reset,
  } = useForm<AuthenticationCodeFormSchema>({
    resolver: zodResolver(authenticationCodeFormSchema),
    mode: 'onChange',
    defaultValues: {
      authenticationCode: ''
    }
  });

  useEffect(() => {
    if (isInputted) {
      setFocus('authenticationCode');
      setReSendTimer(RETRY_SECONDS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInputted]);

  useEffect(() => {
    if (reSendTimer >= RETRY_SECONDS) {
      if (sendTimerId.current && sendTimerId.current !== 0) return;

      sendTimerId.current = window.setInterval(() => {
        setReSendTimer((prev) => {
          if (prev > 0) {
            return prev - 1;
          }
          clearInterval(sendTimerId.current);
          sendTimerId.current = 0;
          return 0;
        });
      }, 1000);
    }
  }, [reSendTimer]);

  const verifyMutation = useCustomerVerifyTelOrEmailMutation();
  const { isLoading: isVerifyLoading } = verifyMutation;

  const handleVerifySubmit = useCallback(async (data?: TelOrEmailInput): Promise<boolean> => {
    const { email, tel, nameKana } = data ?? telOrEmailInput;
    let params: CustomerVerifyTelOrEmailMutationVariables;

    if (telOrEmail === 'EMAIL' && email) {
      params = {
        verifyTelOrEmailMutationInput: {
          params: {
            telOrEmail: email,
            authenticationKind,
          }
        }
      };
    } else if (telOrEmail === 'TEL' && tel) {
      params = {
        verifyTelOrEmailMutationInput: {
          params: {
            telOrEmail: tel,
            authenticationKind,
          }
        }
      };
    } else if (telOrEmail === 'TEL_WITH_KANA' && tel && nameKana) {
      params = {
        verifyTelOrEmailMutationInput: {
          params: {
            telOrEmail: tel,
            nameKana,
            authenticationKind,
          }
        }
      };
    } else {
      throw new Error(`telOrEmail: ${telOrEmail}, tel: ${tel}, email: ${email}, nameKana: ${nameKana} が不正です`);
    }

    try {
      const { customerVerifyTelOrEmail: { result, authenticationToken } } = await verifyMutation.mutateAsync(params);
      if (result && authenticationToken) {
        setAuthenticationToken(authenticationToken);
        return true;
      }
      return false;
    } catch (error) {
      if (error instanceof GraphQLValidationError) {
        toast.error(error.validationError.fullMessages.join('\n'), { duration: 5000 });
      }
      return false;
    }
  }, [authenticationKind, telOrEmail, telOrEmailInput, verifyMutation]);

  // TEL or Emailが入力完了した際に実行する
  const handleInputSubmit = useCallback(async (data: { email?: string, tel?: string, nameKana?: string }) => {
    if (await handleVerifySubmit(data)) {
      setIsInputted(true);
      setTelOrEmailInput(data);
      reset();
    }
  }, [handleVerifySubmit, reset]);

  const reSendVerify = useCallback(async () => {
    if (await handleVerifySubmit()) {
      setReSendTimer(RETRY_SECONDS);
      toast.success('認証コードを再送しました', { duration: 5000 });
      reset();
    }
  }, [handleVerifySubmit, reset]);

  const handleReInput = useCallback(() => {
    setIsInputted(false);
    setIsAuthorized(false);
    onReInput && onReInput();
  }, [onReInput]);

  const codeMutation = useCustomerValidateAuthenticationCodeMutation();

  // 認証コードを認証する
  const handleCodeSubmit = useMemo(() => {
    return handleSubmit(async ({ authenticationCode }) => {
      try {
        const { customerValidateAuthenticationCode } = await codeMutation.mutateAsync({
          validateAuthenticationCodeMutationInput: {
            params: {
              authenticationCode,
              authenticationToken,
            }
          }
        });
        if (customerValidateAuthenticationCode.result) {
          onAuthorized(customerValidateAuthenticationCode, telOrEmailInput);
          setIsAuthorized(true);
        }
      } catch (error) {
        if (error instanceof GraphQLValidationError) {
          toast.error(error.validationError.fullMessages.join('\n'), { duration: 5000 });
        }
      }
    });
  }, [handleSubmit, codeMutation, authenticationToken, onAuthorized, telOrEmailInput]);

  const handleEnterKeyDownCode = useCallback(async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== 'Enter') return false;
    if (composing) return false;

    e.preventDefault();
    await handleCodeSubmit();
  }, [composing, handleCodeSubmit]);

  return (
    <Box {...rest}>
      {telOrEmail === 'EMAIL' && (
        <EmailInput
          defaultEmail={defaultEmail}
          isDisabled={isInputted}
          isLoading={isVerifyLoading}
          isAuthorized={isAuthorized}
          onReInput={handleReInput}
          onSubmit={handleInputSubmit}
        />
      )}

      {telOrEmail === 'TEL' && (
        <TelInput
          defaultTel={defaultTel}
          isDisabled={isInputted}
          isLoading={isVerifyLoading}
          isAuthorized={isAuthorized}
          onReInput={handleReInput}
          onSubmit={handleInputSubmit}
        />
      )}

      {telOrEmail === 'TEL_WITH_KANA' && (
        <TelWithKanaInput
          defaultTel={defaultTel}
          isDisabled={isInputted}
          isLoading={isVerifyLoading}
          isAuthorized={isAuthorized}
          defaultNameKana={defaultNameKana}
          onReInput={handleReInput}
          onSubmit={handleInputSubmit}
        />
      )}

      <FormControl
        isInvalid={!!errorMessage}
      >
        <FormErrorMessage>
          {errorMessage}
        </FormErrorMessage>
      </FormControl>

      <Modal
        isOpen={isInputted && !isAuthorized}
        onClose={handleReInput}
        isCentered={true}
        size={'lg'}
      >
        <ModalOverlay/>
        <ModalContent>
          <ModalHeader>
            認証コードを入力
          </ModalHeader>
          <ModalCloseButton/>
          <ModalBody
            pb={8}
            px={4}
          >
            <Box
              textStyle={'h4'}
              textAlign={'center'}
              mx={'auto'}
              mb={4}
            >
              {(() => {
                const inputValue = telOrEmailInput.tel && ['TEL', 'TEL_WITH_KANA'].includes(telOrEmail)
                  ? telOrEmailInput.tel
                  : telOrEmailInput.email;
                return (
                  <>
                    <Text><Code wordBreak={'break-all'}>{inputValue}</Code>宛に認証コードを送信しました。</Text>
                    <Text>{sendTypeName}に記載された認証コードを入力してください。</Text>
                  </>
                );
              })()}
            </Box>
            <Box
              mt={4}
              textAlign={'center'}
            >
              <FormControl
                isInvalid={!!errors.authenticationCode}
                isRequired={true}
                w={{ base: 'full', sm: '330px' }}
                mx={'auto'}
              >
                <FormLabel
                  htmlFor="code"
                  requiredIndicator={<RequiredIndicator/>}
                >
                  認証コード
                </FormLabel>

                <InputGroup
                  size="md"
                >
                  <AuthenticationCodeInput
                    {...register('authenticationCode')}
                    id={'code'}
                    onCompositionStart={handleStartComposition}
                    onCompositionEnd={handleEndComposition}
                    onKeyDown={handleEnterKeyDownCode}
                  />
                  <InputRightElement width="5.5rem">
                    <Button
                      isLoading={isSubmitting}
                      h="1.75rem"
                      size="sm"
                      colorScheme={'brand'}
                      onClick={handleCodeSubmit}
                    >
                      認証する
                    </Button>
                  </InputRightElement>
                </InputGroup>
                <FormErrorMessage>
                  {errors.authenticationCode?.message}
                </FormErrorMessage>
              </FormControl>

              <Text
                textAlign={'center'}
                textStyle={'h6'}
                mx={'auto'}
                mt={8}
                mb={4}
              >
                {sendTypeName}が届かない場合は、入力した{inputTypeName}に誤りがないかご確認ください。
              </Text>
              <Button
                size={'sm'}
                colorScheme={'brand'}
                variant={'outline'}
                isLoading={isVerifyLoading}
                isDisabled={reSendTimer > 0}
                onClick={reSendVerify}
              >
                {reSendTimer > 0 && `${reSendTimer}秒後に`}認証コードを再送
              </Button>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              size={'sm'}
              colorScheme={'red'}
              onClick={handleReInput}
            >
              {inputTypeName}を再入力する
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};
